@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Cairo";
  font-weight: normal;
  font-style: normal;
}